import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'i18n';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { XS } from 'consts/media';
import MoreMenu from 'components/MoreMenu';
import Loader from 'components/Loader';
import Content from 'components/Content';
import Table from 'components/Table';
import Title from 'components/Title';
import { logAndShowGenericError } from 'utils/log';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { PERMISSION_ORGANIZATION_MANAGE } from 'consts/permissions';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useToggleState from 'hooks/useToggleState';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';

import REJECTION_REASONS_QUERY from './rejectionReasonsQuery.gql';
import DELETE_REJECTION_REASON_MUTATION from './deleteRejectionReasonMutation.gql';
import RejectionReasonAddModal from './RejectionReasonModal/RejectionReasonAddModal';
import RejectionReasonEditModal from './RejectionReasonModal/RejectionReasonEditModal';

const getMoreMenu = (t) => [
  {
    key: 'edit',
    iconComponent: EditOutlined,
    content: t('RejectionReasonsSettings_edit'),
    dataRole: 'edit-rejection-reason',
  },
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('RejectionReasonsSettings_delete'),
    dataRole: 'delete-rejection-reason',
  },
];

function RejectionReasonsSettings() {
  const { t } = useTranslation();
  const [organizationPk, isOrganizationLoading] = useCurrentOrganization();
  const browserWidth = useBrowserWidth();

  const [isModalVisible, onModalOpen, onModalClose] = useToggleState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentReason, setCurrentReason] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const { data, loading, refetch } = useQuery(REJECTION_REASONS_QUERY, {
    variables: { organizationPk },
    skip: isOrganizationLoading,
  });

  const [deleteRejectionReason] = useMutation(DELETE_REJECTION_REASON_MUTATION);

  const [hasOrganizationManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_ORGANIZATION_MANAGE]);

  const rejectionReasons = data?.viewer?.rejectionReasons || [];

  useEffect(() => {
    if (updateTrigger > 0) {
      refetch();
    }
  }, [updateTrigger, refetch]);

  const handleDelete = useCallback(
    async (reason) => {
      setIsSaving(true);
      try {
        const result = await deleteRejectionReason({
          variables: {
            pk: reason.pk,
            organizationPk,
          },
        });

        if (result.data?.deleteRejectionReason?.success) {
          message.success(t('RejectionReasonsSettings_message-reasonDeleted'));
          setUpdateTrigger((prev) => prev + 1);
        } else {
          message.error(t('RejectionReasonsSettings_message-deletionFailed'));
        }
      } catch (error) {
        logAndShowGenericError('Error deleting rejection reason:', error);
      } finally {
        setIsSaving(false);
      }
    },
    [deleteRejectionReason, organizationPk, t],
  );

  const onItemClick = useCallback(
    (key, reason) => {
      if (isSaving) return;

      if (key === 'edit') {
        setCurrentReason(reason);
        onModalOpen();
      } else if (key === 'delete') {
        confirmDeleteModal(
          t('RejectionReasonsSettings_sureWantToDelete', { name: reason.name }),
          () => handleDelete(reason),
        );
      }
    },
    [onModalOpen, handleDelete, t, isSaving],
  );

  const handleModalClose = useCallback(() => {
    onModalClose();
    setCurrentReason(null);
    setUpdateTrigger((prev) => prev + 1);
  }, [onModalClose]);

  const COLUMNS = [
    {
      title: t('RejectionReasonsSettings_column-name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (_, reason) => (
        <MoreMenu
          id={reason.pk}
          items={getMoreMenu(t)}
          onItemClick={
            hasOrganizationManagePermission
              ? (key) => onItemClick(key, reason)
              : showNoPermissionMessage
          }
          menuTitle={reason.name}
        />
      ),
    },
  ];

  if (loading || isOrganizationLoading) return <Loader fullSize />;

  return (
    <Content
      title={t('RejectionReasonsSettings_title')}
      secondary
      size="small"
      condensed
      withHeaderSpacing
      CTAText={t('RejectionReasonsSettings_addReason')}
      onCTAClick={
        hasOrganizationManagePermission
          ? () => {
              setCurrentReason(null);
              onModalOpen();
            }
          : showNoPermissionMessage
      }
    >
      <Title>{t('Title_rejectionReasonsSettings')}</Title>
      <Table
        columns={COLUMNS}
        dataSource={rejectionReasons.map((reason) => ({
          key: reason.pk,
          ...reason,
        }))}
        pagination={{
          hideOnSinglePage: true,
        }}
        showHeader={browserWidth > XS}
      />
      {isModalVisible && !currentReason && (
        <RejectionReasonAddModal
          isVisible={isModalVisible}
          onCancel={handleModalClose}
        />
      )}
      {isModalVisible && currentReason && (
        <RejectionReasonEditModal
          isVisible={isModalVisible}
          onCancel={handleModalClose}
          reasonPk={currentReason.pk}
          initialName={currentReason.name}
        />
      )}
    </Content>
  );
}

export default RejectionReasonsSettings;
